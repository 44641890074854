<template>
    <!-- 首页 -->
    <div class="pdfView">
      <iframe style="width:100%;height:100%;" :src="url" frameborder="0"></iframe>
    </div>
  </template>
  <script>
  export default {
    name: 'pdfView',
    data() {
      return {
        ok: false, // 是否加载完成
        pdfh5: null,
        host: '',
        url: ''
      }
    },
    watch: {
      pdf: {
        handler(n) {
          if (n) {
            console.log(this.pdf)
            
          }
        },
        deep: true,
        immediate: true
      }
    },
    created(){
        let link = decodeURIComponent(this.$route.query.pdf);
        console.log(this.$route.query,'dsdsdddsd')
        var u = navigator.userAgent
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
        this.host = link.slice(0, parseInt(link.indexOf('v8filesvr')))
        this.url = this.host + 'pdf/web/viewer.html?file=' + decodeURIComponent(this.$route.query.pdf);
        console.log(this.url,'dsddsdsdds')
        // if (isAndroid) {
        //   //android终端
        //   window.open(this.url)
        // } else if (isiOS) {
        //   //ios终端
        //   window.location.href = this.url
        // } else {
        //   window.open(this.url)
        // }
    },
    mounted () {
        this.$nextTick(()=>{
            let _this = this;
            window.addEventListener("visibilitychange", function () { 
                //部分手机可以获取到
                if (document.hidden) {
                    _this.listenerClose();
                }
            })
            window.addEventListener("pagehide", () => {
                _this.listenerClose();
            })
            window.addEventListener("popstate", function(e) {
                _this.listenerClose();
            })
            window.addEventListener('onunload', _this.listenerWindow)
        })
    },
    methods:{
        listenerWindow(event) {
            this.listenerClose();event.preventDefault();event.returnValue = '是否离开？';return true;
        },
        listenerClose(){
            let _this = this;
            var img = new Image();
            let baseUrl = window.BASE_URL?window.BASE_URL:'https://v8.hyclound.cn:8080/v8api';
            let apiUrl = "/api/wxpx/saveWatchLog_End";
            let data =
                "?empid=" +
                _this.$route.query.empid +
                "&mautoid=" +
                _this.$route.query.mautoid +
                "&cw_autoid=" +
                _this.$route.query.cw_autoid +
                "&guid=" +
                _this.$route.query.guid +
                "&videotimes=" +
                0 +
                "&lastpos=" +
                0 +
                "&username=" +
                _this.$route.query.username +
                "&isfinish=0";
            img.style.display = "none";
            img.src = baseUrl + apiUrl + data; // 服务端处理接口
            document.body.appendChild(img);
        }
    }
  }
  </script>
  <style lang="less" scoped>
  .pdfView {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    .container {
      background: #f6f6f6;
      width: 100%;
      overflow: hidden;
      height: 100%;
      position: relative;
      // 主体
      .ConsultingCenter {
        width: 100%;
        height: 100vh;
        margin: 0;
        padding: 0;
      }
    }
  }
  </style>